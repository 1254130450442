#icon {
    color: rgb(0, 0, 0);
    font-size: 20px;
}

#title {
    font-weight: bold;
}

.sideNav {
    z-index: -99;
}

.table {
    z-index: 99;
}